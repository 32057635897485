export default [{
  path: '/carManage/enterWarehouse/index',
  component: resolve => import('../enterWarehouseManage/Index.vue').then(resolve),
  meta: {
    title: '入库管理',
    nav: '/enterWarehouseManage/index',
  },
},
{
  path: '/carManage/enterWarehouse/carDetail',
  component: resolve => import('../enterWarehouseManage/CarDetail.vue').then(resolve),
  meta: {
    title: '车辆详情',
    nav: '/carManage/enterWarehouse/carDetail',
  },
},
{
  path: '/carManage/enterWarehouse/submitPick',
  component: resolve => import('../enterWarehouseManage/SubmitPick.vue').then(resolve),
  meta: {
    title: '提交接车材料',
    nav: '/carManage/enterWarehouse/submitPick',
  },
},
{
  path: '/carManage/enterWarehouse/pickDetail',
  component: resolve => import('../enterWarehouseManage/PickDetail.vue').then(resolve),
  meta: {
    title: '接车材料详情',
    nav: '/carManage/enterWarehouse/pickDetail',
  },
},
{
  path: '/carManage/stock/index',
  component: resolve => import('../stockManage/Index.vue').then(resolve),
  meta: {
    title: '库存管理',
    nav: '/carManage/stock/index',
  },
},
{
  path: '/carManage/stock/carInfo',
  component: resolve => import('../stockManage/CarInfo.vue').then(resolve),
  meta: {
    title: '车辆信息',
    nav: '/carManage/stock/carInfo',
  },
},
{
  path: '/carManage/stock/orderDetail',
  component: resolve => import('../stockManage/OrderDetail.vue').then(resolve),
  meta: {
    title: '订单详情',
    nav: '/carManage/stock/orderDetail',
  },
},
{
  path: '/carManage/stock/transferInfo',
  component: resolve => import('../stockManage/TransferInfo.vue').then(resolve),
  meta: {
    title: '过户信息',
    nav: '/carManage/stock/transferInfo',
  },
},
{
  path: '/carManage/stock/bidInfo',
  component: resolve => import('../stockManage/BidInfo.vue').then(resolve),
  meta: {
    title: '出价信息',
    nav: '/carManage/stock/bidInfo',
  },
},
{
  path: '/carManage/stock/carDetail',
  component: resolve => import('../stockManage/CarDetail.vue').then(resolve),
  meta: {
    title: '车辆详情',
    nav: '/carManage/stock/carDetail',
  },
},]
