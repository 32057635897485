export default {
  // 库存管理
  getCarManagement: ['get', '/car_management/to_stock/list'], // 入库管理
  getCarInfo: ['get', '/car_management/:center_car_id/brief_info'], // 车辆信息
  getBidRecord: ['get', '/car_management/:center_car_id/auction_record'], // 参拍记录
  getBidInfo: ['get', '/car_management/:center_car_id/bid_record/:auction_id'], // 出价信息
  postOutStock: ['post', '/car_management/:center_car_id/out_stock'], // 库存管理-交车出库
  updateOutStock: ['put', '/car_management/:center_car_id/out_stock'], //  库存管理-交车出库（编辑）
  postReivewInfo: ['post', '/car_management/to_stock/:center_car_id/material'], // 提交接车资料
  getReviewInfo: ['get', '/car_management/to_stock/:center_car_id/material'], // 接车资料详情
  postReviewPass: ['post', '/car_management/to_stock/:center_car_id/review/success'], // 审核通过
  postReviewReject: ['post', '/car_management/to_stock/:center_car_id/review/failure'], // 审核拒绝
  getReviewTraces: ['get', '/car_management/to_stock/:center_car_id/review/traces'], // 查看审核记录
  getStockList: ['get', '/car_management/in_stock/list'], // 库存管理
  getTransferInfo: ['get', '/car_management/:center_car_id/transfer'], // 过户信息
  getOrderDetail: ['get', '/car_management/:center_car_id/auction_order/:auction_id'], // 订单详情
  getStockCarDetail: ['get', '/car_management/in_stock/:center_car_id/car_detail'],
  postTrace: ['post', '/car_management/trace/:center_car_id'], // 新增轨迹
  editTrace: ['put', '/car_management/:center_car_id/trace/:trace_id'], // 编辑轨迹
  deleteTrace: ['delete', '/car_management/:center_car_id/trace/:trace_id'], // 删除轨迹
  getTraceInfos: ['get', '/car_management/trace/:center_car_id'], // 查看轨迹
  getEnterCarDetail: ['get', '/car_management/to_stock/:center_car_id/car_detail'],
  getOutStock: ['get', '/car_management/:center_car_id/out_stock'], // 出库信息
  getConsignees: ['get', '/car_dealer/consignor/:mobile/consignee'], // 车商备案书
  getSessionTmpl: ['get', '/car_management/session_tmpl']
}
